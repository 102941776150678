// 路由登录白名单
export const whiteList = ['/personal','/examinee','/openvip','/setpwd','/diagnosis','/diagnosis-info'];

//省份数据
export const ProvinceData = [
	{value:"1",text:"安徽",char:"A"},
	{value:"2",text:"北京",char:"B"},
	{value:"3",text:"重庆",char:"C"},
	{value:"4",text:"福建",char:"F"},
	{value:"5",text:"广东",char:"G"},
	{value:"6",text:"广西",char:"G"},
	{value:"7",text:"甘肃",char:"G"},
	{value:"8",text:"贵州",char:"G"},
	{value:"9",text:"河北",char:"H"},
	{value:"10",text:"河南",char:"H"},
	{value:"11",text:"海南",char:"H"},
	{value:"12",text:"湖北",char:"H"},
	{value:"13",text:"湖南",char:"H"},
	{value:"14",text:"黑龙江",char:"H"},
	{value:"15",text:"吉林",char:"J"},
	{value:"16",text:"江苏",char:"J"},
	{value:"17",text:"江西",char:"J"},
	{value:"18",text:"辽宁",char:"L"},
	{value:"19",text:"内蒙古",char:"N"},
	{value:"20",text:"宁夏",char:"N"},
	{value:"21",text:"青海",char:"Q"},
	{value:"22",text:"上海",char:"S"},
	{value:"23",text:"四川",char:"S"},
	{value:"24",text:"山东",char:"S"},
	{value:"25",text:"山西",char:"S"},
	{value:"26",text:"陕西",char:"S"},
	{value:"27",text:"天津",char:"T"},
	{value:"28",text:"新疆",char:"X"},
	{value:"29",text:"云南",char:"Y"},
	{value:"30",text:"浙江",char:"Z"}
];


export const areaData = {
    '16544791295722496':'CF39E965-98C6-488E-A969-C6C25781CBA6',
	'16544791295722497':'0C500669-C9B5-48D0-B848-3CCF71E6F53B',
    '16544791295722498':'5F4FF010-4FF3-4442-B65A-4D3870D71254',
    '16544791295722499':'AE4CAB95-53B8-4B93-A48A-59EBAF39814C',
    '16544791295722500':'6F6CEDC9-933C-429D-A79C-54F667936E81',
    '16544791295722501':'4C0122C9-DF13-447E-84EE-AF51DCB5C0D9',
    '16544791295722502':'220F1FFE-86F7-4698-A972-508238966F84',
    '16544791295722503':'A949C8B6-40BB-456A-AF55-FB4F6FBCD947',
    '16544791295722504':'0E5DCBFE-75DA-4319-8BA8-AE08EE1C4C19',
    '16544791295722505':'E57AB023-3B98-48CD-839F-D29922A976A5',
    '16544791295722506':'614DE43A-4D17-4639-A19C-3221B0E80E0B',
    '16544791295722507':'830AC47A-B3DD-4E94-8CFC-1AF0E4B43F45',
    '16544791295722508':'38220A9C-2AFA-4A42-A18E-71876BB5D629',
    '16544791295722509':'4A3353D7-9B17-4325-8004-7EFFDBE2044F',
    '16544791295722510':'7CAE0EDD-A5DF-403B-9853-B1529FDFDAD1',
    '16544791295722511':'7F42BBF2-968A-40A6-9E64-3B2383D1EB43',
    '16544791295722512':'B7DF5ACA-27CE-408D-8C7D-2C3AE76CBB3D',
    '16544791295722513':'6BD52781-2F8E-4114-8150-5DEB3AC228D3',
    '16544791295722514':'F82A7D17-BFA4-4F55-983C-1DD747369722',
    '16544791295722515':'B8D010C8-F621-40A9-86DD-D8EDBF32E848',
    '16544791295722516':'C2EC2B69-62FF-474D-A22E-E57A4B42B5EE',
    '16544791295722517':'52E24F98-63B3-47F0-960C-45015D6D7B3F',
    '16544791295722518':'C9384EB7-F73E-4501-BCB5-325710EFCC8B',
    '16544791295722519':'54CB098B-256E-4487-974F-511AE8CA1CA5',
    '16544791295722520':'D0C9D87F-A620-4763-A790-F7E9A05D7928',
    '16544791295722521':'D1D97AE4-D369-4E67-9854-382FF768ADAA',
    '16544791295722522':'73979981-355C-4316-91D0-839E759D0BB7',
    '16544791295722523':'90940F2D-7F2E-4258-B246-49DAC7EB2A79',
    '16544791295722524':'821CA668-6605-4C5B-BBE7-759BB1C29E01',
    '16544791295722525':'FFA11601-2147-42BB-AAD3-B33BF90AE07A',
    '16544791295722526':'CA8D78B7-B906-4261-95E0-305335FFEA6D',
    '16544791295722527':'BF5D9C5E-547D-4D2E-9A51-56BEFD327EE3',
    '16544791295722528':'53C27809-B92B-4B32-9CFE-63505B04BF2A',
    '16544791295722529':'8430BBF3-7968-4D39-ABD3-259730FC2A77'
}