import axios from 'axios';
export default {
    Personal:{

        /****************** 公共 相关 ******************/

        // 获取学生用户基本信息 /api/User/GetUserStudent
        GetUserStudent(data){
            return axios.post('/api/User/GetUserStudent', data);
        },
        // 修改学生用户基本信息 /api/User/UpdateUserStudent
        UpdateUserStudent(data){
            return axios.post('/api/User/UpdateUserStudent', data);
        },
        // 修改学生用户高考信息 /api/User/UpdateUserExam // 旧接口
        UpdateUserExam(data){
            return axios.post('/api/User/UpdateUserExam', data);
        },
        // 新接口
        UpdateUserScore(data){
            return axios.post('/api/User/UpdateUserScore', data);
        },

        // 激活会员卡 /api/User/ActivationCard
        ActivationCard(data){
            return axios.post('/api/User/ActivationCard', data);
        },
        // 获取用户产品授权 /api/User/GetUserProducts
        GetUserProducts(data){
            return axios.post('/api/User/GetUserProducts', data);
        },
        // 设置初始登录账号 /api/Auth/SetInitialLoginName
        SetInitialLoginName(data){
            return axios.post('/api/Auth/SetInitialLoginName', data);
        },
        // 检查登录名是否存在 /api/Auth/CheckLoginNameExists
        CheckLoginNameExists(data){
            return axios.post('/api/Auth/CheckLoginNameExists', data);
        },
        // 重置密码 /api/Auth/ResetPassword
        ResetPassword(data){
            return axios.post('/api/Auth/ResetPassword', data);
        },
        RegisterAndLogin(data) {
            return axios.post('/api/Auth/RegisterAndLogin', data)
        }

    }
    
}