<template>
  <div id="app">
    <!-- <keep-alive :exclude="excludeRouterList">
      <router-view ref="appview" v-if="pageshow" />
    </keep-alive> -->
    <router-view ref="appview" v-if="pageshow" />

  </div>
</template>
<script>
import {getStore,setStore, removeStore,setExamProvince} from '@/utils/utils';
import API from "./api/config";
export default{
  data(){
    return {
      pageshow:false,
      excludeRouterList:[
        'Expert_List',
        'Content_Page'
      ]
    }
  },
  beforeCreate(){
    API.Web.WebConfig().then(res => {
      if(res.Code===0){
        setStore("ParamList",res.Data.ParamList);
        setStore("ModuleList",res.Data.ModuleList);
        setStore("HeadMenuList",res.Data.HeadMenuList);
        setStore("BottomMenuList",res.Data.BottomMenuList);
        setStore("ProvinceList",res.Data.ProvinceList);
        setStore("ProductCodes",res.Data.ProductCodes);
        setStore("ProductAlias",res.Data.ProductAlias);
        setStore("WishYear",res.Data.WishYear);
        setExamProvince(res.Data.ProvinceList);

        this.$store.commit('user/set_nameConfig', res.Data.ProductAlias)

        this.pageshow = true;
      }
    });
    // API.Web.GetWishProvinceAll().then(res => {
      
    //   setExamProvince(res.Data);
    //   // setStore("ProvinceList",res.Data);
    // })

    // 配置主题颜色
    let tc=getStore("ThemeColor");
    if(tc!=undefined&&tc!=null&tc!=""){
      document.documentElement.style.setProperty("--themecolor", tc);
    }

  },
  watch: {
    $route: function(to, from) {
      // 清除脚本
      let dom_js_arr = window.localStorage.getItem('ScriptInjectList');
      if(dom_js_arr!=undefined&&dom_js_arr!=null&&dom_js_arr!=''){
        let json_data = JSON.parse(dom_js_arr);
        json_data.forEach(item=>{
          this.deletejs(item);
        })
      }
      // 脚本注入
      this.impotjs(to);
    }
  },
  mounted(){
    // 移除加载板块
   
    // this.$store.dispatch('user/judgeLoginStatus').then(flag => {
    //   if (!flag) {
    //     removeStore('LoginData')
    //   }
    // })
    
  },
  methods:{

    // 删除脚本
    deletejs(item){
      let obj = document.getElementById(item.ScriptKey);
      if(obj!=null&&obj!=undefined){
        obj.remove();
        this.deletejs(item);
      }
    },

    // 判断注入脚本方式
    impotjs(to){
      // 获取脚注入本
      let st_data = window.localStorage.getItem('ScriptInjectList');
      if(st_data!=undefined&&st_data!=null&&st_data!=''){
        let json_data = JSON.parse(st_data);
        json_data.forEach(item => {
          // 判断是否全局注入
          if(item.InjectPageList!=null && item.InjectPageList.length>0){
            // 局部注入
            let r = item.InjectPageList.find(f=>f==to.path);
            //判断当前路由是否在配置的路由中
            if(r!=undefined){
              // 开始注入
              this.insertjs(item);
            }
          }
          else{
            // 全局注入
            this.insertjs(item);
          }
        });
      }
    },

    // 创建注入脚本
    insertjs(item){
      if(item.ImportScriptList != null && item.ImportScriptList.length > 0){
        item.ImportScriptList.forEach(scriptFilePath => {
          // 引入外部脚本
          let script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = scriptFilePath;
          script.id = item.ScriptKey;
          document.body.appendChild(script);
        });
      }
      if(item.ScriptCode!=null&&item.ScriptCode!=''){
        // 创建代码脚本
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.text = item.ScriptCode;
        script.id = item.ScriptKey;
        document.body.appendChild(script);
      }
    }
  }
}
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.el-loading-spinner i{
  color: rgba(var(--themecolor),0.55) !important;
  font-size: 40px;
}
.el-loading-spinner .el-loading-text{
  color: rgba(var(--themecolor),0.55) !important;
  font-size: 14px;
}
.el-loading-spinner .path{
  stroke: rgba(var(--themecolor),0.55) !important;
}
</style>