import Vue from 'vue';
import VueRouter from 'vue-router';
import {getStore, setStore, removeStore} from '../utils/utils';
import {whiteList} from '../utils/data';
import axios from 'axios'
import Config from '@/config/config';
import API from "@/api/config";
import store from '@/store'
Vue.use(VueRouter);

const routes = [];

//导入modules模块
const modulesContext = require.context('./modules/', true, /\.js$/);
modulesContext.keys().reduce((modules, key) => {
  //将各模块路由合并到routes
  Array.prototype.push.apply(routes, modulesContext(key).default);
}, {});

//解决重复跳转路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
  mode: 'hash',//路由模式：hash、history、abstract
  base: process.env.BASE_URL,//配置单页应用的基路径
  routes,
  //在按下 后退/前进 按钮时，页面滚到顶部，或者是保持原先的滚动位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  // let AU = window.location.href.split('Token=')
  let AU = window.location.href
  if (AU) {
    AU = decodeURIComponent(AU)
    let AUList = AU.split('Token=')

    let SSOP = AUList[1]
    let baseUrl = AUList[0]
    if (SSOP) { // sso 登录

      let i = SSOP.indexOf('&')

      let token, reUrl, temp
      if (i==-1) {
        token = SSOP
        reUrl = baseUrl
      } else {
        token = SSOP.substring(0, i)

        temp = SSOP.substring(i+1)
        reUrl = baseUrl+temp
      }

      let apiUrl = APIHost?APIHost:Config.APIHost

      axios.create({
        baseURL: apiUrl+'/api/Auth/LoginStatus',
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          token: token,
          orgCode: getStore('OrgCode'),
          terminalType: 0
        }
      })().then(res => {
        

        if (res.data.Data != null) {
          
          setStore('LoginData', res.data.Data)
          setStore('RightsList', res.data.Data.RightsList)

          store.dispatch('user/getStudentInfo').then(userInfo => {
            removeStore('tempScoreInfo')
            let province = getStore('ExamProvince')
            if (!province) {
                this.$message.error('无省份数据！')
                return false
            }
            province = JSON.parse(province)

            if (userInfo.UserScore) {
                
              if (userInfo.UserScore.ExamProvinceId) {
                let obj = province.find(item => item.ProvinceId === userInfo.UserScore.ExamProvinceId)
                
                if (obj) {
                    setStore('OrgProvince', obj)
                } else {
                    this.$message.error('ExamProvinceId未更新！')
                }
              }
            }
            API.User.CheckUserStudentComplate().then(res => {
              
                if (res.code==0) {
                    if (res.data.IsNeedComplateBaseInfo) {
                      next("/personal");
                    } else if (res.data.IsNeedComplateScore) {
                      next("/examinee");
                    } else {
                      window.location.replace(reUrl)
                      window.location.reload()
                    }
                } else {
                  window.location.replace(reUrl)
                  window.location.reload()
                }
            }).catch(err => {
              
            })
          })
        } else {
          // removeStore('LoginData')
        }
      })
      
    } else {

    }
  }
  
  // 当前路由在登录白名单中 验证是否登录
  if (whiteList.indexOf(to.path) !== -1) {
    // 判断是否已登录
    let user = getStore("LoginData");
    
    if(user!=undefined&&user!=null&&user!=""){
      next();// 正常跳转
    }
    else {
      next({path: '/login'});// 转回登录界面
    }
  }
  // 不在白名单中，则直接进入该路由地址
  else {
    if (to.path !== '/login') {
      next()
    } else {
      let user = getStore("LoginData");
      if(!user){
        next();// 正常跳转
      } else {
        next({path: '/'})
      }
    }
    next();
  }

  // // 第一次清除 注入脚本
  // let dom_js_arr = window.localStorage.getItem('ScriptInjectList');
  // if(dom_js_arr!=undefined&&dom_js_arr!=null&&dom_js_arr!=''){
  //   let json_data = JSON.parse(dom_js_arr);
  //   json_data.forEach(item=>{
  //     let obj = document.getElementById(item.ScriptKey);
  //     if(obj!=null&&obj!=undefined){
  //       obj.remove();
  //     }
  //   })
  // }
  // // 第二次清除 注入脚本
  // if(dom_js_arr!=undefined&&dom_js_arr!=null&&dom_js_arr!=''){
  //   let json_data = JSON.parse(dom_js_arr);
  //   json_data.forEach(item => {
  //     let obj = document.getElementById(item.ScriptKey);
  //     if(obj!=null&&obj!=undefined){
  //       obj.remove();
  //     }
  //   })
  // }
});

// router.afterEach((to, from, next) => {

// })

export default router